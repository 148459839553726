import React from "react"
import Layout from "../templates/layout"
import Topography from '../components/topography-section'
import Hero from '../components/hero'
import H1 from '../components/typography/h1'
import P from '../components/typography/p'
import Button from '../components/button'
import Container from '../components/container'


const ContactUs = ({ data }) => (
  <Layout
    title="Contact Us | Loveless Outdoor Adventures"
    description="Have questions about bowfishing, catfishing, or duck hunting on Kentucky Lake? Contact us today and we'll be happy to answer any questions you have on our services."
  >
    <Hero title="looks like you got skunk'd" tagline="Try to find another spot" />
    <Topography>
      <Container>
        <H1>404 Page Not Found</H1>
        <P>The page you are looking for has either been removed or doesn't exist</P>
        <Button link="/" buttonLabel="View Home Page" />
      </Container>
    </Topography>
  </Layout>

)


export default ContactUs
 